<template>
  <div class="not-found">
    <div class="not-found__content">
      <div class="not-found__content--title text-h2 text-md-h1 text-xl-h1">
        Oops!
      </div>
      <div class="not-found__content--subtitle text-h6 text-md-h6 text-xl-h4">
        This page unfortunately was not found
      </div>
      <v-btn
        large
        color="success"
        depressed
        rounded
        class="not-found__content--btn"
        @click="goToHome"
      >
        Go back
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style src="./NotFound.scss" lang="scss" scoped></style>
